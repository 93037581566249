<template>
  <div style="position: relative">
    <Breadcrumb :Breadcrumb="BreadcrumbCon" />
    <div class="search">
      <el-select
        v-model="valueArea"
        filterable
        clearable
        placeholder="请选择区号(可搜索关键字)"
        style="width: 240px"
        v-if="internationType"
      >
        <el-option
          v-for="item in options"
          :key="item.name"
          :label="item.name + item.tel"
          :value="item.tel"
        >
        </el-option>
      </el-select>
      <el-input
        v-model="phone"
        width="200"
        placeholder="请输入手机号"
      ></el-input>
      <el-button type="primary" @click="searchList">查询</el-button>
      <el-button type="info" @click="reset">重置</el-button>
    </div>

    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        border
        :header-cell-style="{ background: '#e5e5e5', color: '#4b4b4b' }"
      >
        <el-table-column type="index" label="序号" width="70" align="center">
          <template slot-scope="scope">
            <span>{{ scope.$index + (currentPage - 1) * pageSize + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="realName"
          label="用户名"
          align="center"
          width="130"
        >
        </el-table-column>
        <el-table-column
          prop="phoneArea"
          label="区号"
          align="center"
          width="80"
        >
        </el-table-column>
        <el-table-column prop="phone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="hardwareId" label="趣宝盒ID" align="center">
        </el-table-column>
        <el-table-column
          prop="status"
          label="网络连接"
          width="110"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="untilTime" label="流量有效期" align="center">
        </el-table-column>
        <el-table-column prop="remainingDays" label="剩余天数" align="center">
        </el-table-column>
        <el-table-column
          prop="untilStatus"
          label="续费状态"
          width="150"
          align="center"
        >
          <template slot-scope="scope">
            <div
              v-if="scope.row.untilStatus == '已报废'"
              style="color: #ff3745"
            >
              过期{{ scope.row.expirationDays }}天，已报废
            </div>
            <div v-else>
              {{ scope.row.untilStatus }}
            </div>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100" align="center">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="handleDetail(scope.row)"
              >查看详情</el-button
            >
          </template></el-table-column
        >
      </el-table>
    </div>

    <Pagination
      v-show="pageLength >= 0"
      :total="pageLength"
      :limit.sync="pageSize"
      @pagination="handlePageChange"
      :page-sizes="pageSizes"
    />
  </div>
</template>

<script>
import Breadcrumb from "@/components/BreadCrumb";
import Pagination from "@/components/Pagination";

import { areaCodeData } from "@/util/areaCodeData";

import { reqUrl } from "@/api/apiUrl";
import { request } from "@/api/request";
import { showLoading, hideLoading } from "@/util/loading";
import { format_time_date } from "@/util/funBusiness";

export default {
  inject: ["reload"],
  components: { Breadcrumb, Pagination },

  data() {
    return {
      BreadcrumbCon: [
        {
          name: "行程上链",
        },
        {
          name: "趣宝盒数据",
        },
        {
          name: "流量续费",
        },
        {
          name: "已报废",
        },
      ], // 面包屑数据
      tableData: [],

      // 分页数据
      currentPage: 1, // 当前页
      pageLength: 0,
      pageSize: 10, //要传过去的数据 每页多少条数据
      pageSizes: [10, 50, 100, 200, 500, 1000],

      option: [],
      valueArea: "",
      phone: "",

      internationType: false,
      role: "",
      phoneAreaROLE: "",
    };
  },

  mounted() {
    this.options = areaCodeData;
    this.role = localStorage.getItem("ROLE");

    var strNation = this.role.includes("ROLE_nation");
    if (strNation) {
      this.internationType = true;
    }
    this.phoneAreaROLE = localStorage.getItem("phoneArea");

    showLoading();
    const opt = {
      url: reqUrl.getHardwareUntilTimeList,
      method: "POST",
      params: JSON.stringify({
        renewType: 4,
      }),
      resFunc: (res) => {
        if (res.data.code != 0) {
          this.$notify.error({
            title: "错误",
            message: res.data.message,
          });
          hideLoading();
        } else if (res.data.code == 0) {
          console.log(res.data);
          this.tableData = res.data.data.list;

          if (res.data.data.list) {
            res.data.data.list.map((item) => this.changeItem(item));
          }

          this.pageLength = res.data.data.total;
          this.pageSize = res.data.data.pageSize;
          hideLoading();
        }
        hideLoading();
      },
      errFunc: (err) => {
        this.$notify.error({
          title: "错误",
          message: err,
        });
        hideLoading();
        console.log(err, "err1");
      },
    };
    request(opt);
  },

  methods: {
    searchList() {
      showLoading();
      const opt = {
        url: reqUrl.getHardwareUntilTimeList,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          renewType: 4,
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            console.log(res.data);
            this.tableData = res.data.data.list;
            if (res.data.data.list) {
              res.data.data.list.map((item) => this.changeItem(item));
            }

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handlePageChange(data) {
      showLoading();
      const opt = {
        url: reqUrl.getHardwareUntilTimeList,
        method: "POST",
        params: JSON.stringify({
          phone: this.phone,
          phoneArea: this.valueArea || this.phoneAreaROLE,
          pageNum: data.pageIndex,
          pageSize: data.pageSize,
          renewType: 4,
        }),
        resFunc: (res) => {
          if (res.data.code != 0) {
            this.$notify.error({
              title: "错误",
              message: res.data.message,
            });
            hideLoading();
          } else if (res.data.code == 0) {
            console.log(res.data);
            this.tableData = res.data.data.list;
            if (res.data.data.list) {
              res.data.data.list.map((item) => this.changeItem(item));
            }

            this.pageLength = res.data.data.total;
            this.pageSize = res.data.data.pageSize;
            this.currentPage = data.pageIndex;
            hideLoading();
          }
          hideLoading();
        },
        errFunc: (err) => {
          this.$notify.error({
            title: "错误",
            message: err,
          });
          hideLoading();
          console.log(err, "err1");
        },
      };
      request(opt);
    },

    handleDetail(row) {
      this.$router.push({
        path: "/TrafficRenewal/detail/VoidEd",
      });
      localStorage.setItem("TrafficRenewalDetail", JSON.stringify(row));
    },

    changeItem(item) {
      if (item.status == 0) {
        item.status = "离线";
      } else if (item.status == 1) {
        item.status = "正常";
      }
      if (item.status == 2) {
        item.status = "故障";
      }

      item.untilTime = format_time_date(item.untilTime);

      if (item.untilStatus == 1) {
        item.untilStatus = "已过期";
      } else if (item.untilStatus == 2) {
        item.untilStatus = "待续费";
      } else if (item.untilStatus == 3) {
        item.untilStatus = "已续费";
      } else if (item.untilStatus == 4) {
        item.untilStatus = "已报废";
      }
    },

    reset() {
      this.phone = "";
      this.phoneArea = "";
      this.refresh();
    },

    // 刷新
    refresh() {
      this.reload();
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  margin-bottom: 8px;

  .el-input {
    width: 300px;
    margin: 0 10px;
  }
}
</style>

<style>
.el-popconfirm__main {
  margin-bottom: 10px;
}
</style>
